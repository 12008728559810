/* eslint-disable react/prop-types */

import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { Copy } from "../../../../../assets/svgs/index";
import { SlideContext } from "../../../../../context/SlideContext";
import useRequest from "../../../../../hooks/useRequest";
import { Button } from "../../../Form/index";

const CardBankBillPaymentSuccess = ({ uuid, barCode, linkTicket, discount, pixQrCodeUrl, pixCode }) => {
  const history = useHistory();
  const [plan, setPlan] = useState({});
  const { request } = useRequest();
  const { setEnableClickAway, setShowSlide } = useContext(SlideContext);
  const [pixCopyText, setPixCopyText] = useState("Copiar código Pix");

  setEnableClickAway(false);

  const calculatDiscount = (value, porcentage) => {
    return ((value * (porcentage || 0)) / 100).toFixed(2);
  };

  const closeSlider = () => {
    setShowSlide(false);
    history.push("/");
  };

  useEffect(() => {
    const fetchPlan = async () => {
      const response = await request("GET", "/plan/read/" + uuid);

      setPlan(response);
    };

    fetchPlan();
  }, []);

  return (
    <div className="md:w-[800px] h-full flex flex-col w-full">
      <div className="flex justify-between items-center">
        <h1 className="text-xl font-gray font-medium">Aguardando confirmação do pagamento</h1>
      </div>
      <div className="grid grid-cols-2 md:grid-cols-4 flex-1 ">
        <div className="col-span-2 pt-4">
          <p className="font-light-gray font-medium">Forma de pagamento</p>
          <div className="flex justify-between my-2">
            <p className="font-light-gray text-xs font-bold uppercase">Pix/Boleto</p>
            <p>Pix/Boleto</p>
          </div>
          <p className="font-light-gray font-medium">Plano Adquirido</p>
          <div className="flex justify-between my-2">
            <p className="font-light-gray text-xs font-bold uppercase">{plan.name}</p>
            <p>R$ {plan.price}</p>
          </div>
          <div className="flex justify-between my-2">
            <p className="font-light-gray text-xs font-bold uppercase">Detalhes do Plano</p>
            {plan.lessonType !== "CONVERSATION" ? (
              <p>
                {plan.lessonsCount} {plan.lessonsCount === 1 ? "aula" : "aulas"} por mês
              </p>
            ) : (
              <p>Conversação</p>
            )}
          </div>
          <p className="text-sm font-medium" style={{ color: "#F59300" }}>
            Lembre-se: o pagamento pode levar até 3 dias úteis para ativar seu plano
          </p>
          <hr className="my-5" />
          <div className="flex justify-between my-2">
            <p className="font-light-gray text-xs font-bold uppercase">Subtotal</p>
            <p>R$ {plan.price}</p>
          </div>
          <div className="flex justify-between my-2">
            <p className="font-light-gray text-xs font-bold uppercase">Taxas administrativas</p>
            <p>Grátis</p>
          </div>
          {discount ? (
            <>
              <div className="flex justify-between my-2">
                <p className="font-light-gray text-xs font-bold uppercase">Desconto</p>
                <p>R$ {calculatDiscount(plan.price, discount?.discountPercentage || 0)}</p>
              </div>
              <div className="flex justify-between my-2">
                <p className="font-light-gray text-xs font-bold uppercase">Total a pagar</p>
                <p>R$ {(plan.price - calculatDiscount(plan.price, discount?.discountPercentage || 0)).toFixed(2)}</p>
              </div>
            </>
          ) : (
            <div className="flex justify-between my-2">
              <p className="font-light-gray text-xs font-bold uppercase">Total a pagar</p>
              <p>{plan.price}</p>
            </div>
          )}

          <p className="font-light-gray">Informações do boleto</p>
          <p className="font-light-gray text-sm my-2">Código de barras</p>
          <p className="break-words font-medium my-2">{barCode}</p>
          <div className="flex gap-2 my-2">
            <button
              className="w-2/3 text-sm flex justify-center gap-3 items-center rounded bg-purple-200 text-purple-700 p-2 mt-2"
              onClick={() => {
                navigator.clipboard.writeText(barCode);
              }}>
              <Copy />
              Copiar código
            </button>
            <button
              className="w-2/3 text-sm rounded bg-purple-200 text-purple-700 p-2 mt-2"
              onClick={() => window.open(linkTicket)}>
              Baixar boleto
            </button>
          </div>
        </div>

        <div className="col-span-2 py-4">
          <p className="font-light-gray font-medium text-lg mb-2 text-center" style={{ fontWeight: "bold" }}>
            Pague por Pix
          </p>

          <div className="flex flex-col items-center">
            <img className="w-2/3" src={pixQrCodeUrl} alt="QRCode pix" />

            <button
              className="flex items-center justify-center w-2/3 text-sm rounded bg-purple-200 text-purple-700 p-2 mt-2"
              style={{ height: "40px" }}
              onClick={() => {
                navigator.clipboard.writeText(pixCode);
                setPixCopyText("Código copiado!");
                setTimeout(() => {
                  setPixCopyText("Copiar código Pix");
                }, 1300);
              }}>
              {pixCopyText !== "Código copiado!" && <Copy />}
              {pixCopyText}
            </button>
          </div>
        </div>
        {/* <WorkingGuy className="md:mx-5" style={{ width: "200%" }} /> */}
      </div>
      <div className="grid md:grid-cols-3 gap-2 mt-auto">
        <Button className="border border-purple-800 text-purple-800" onClick={closeSlider}>
          Fechar
        </Button>
        <hr className="mt-4 invisible" />
      </div>
    </div>
  );
};

CardBankBillPaymentSuccess.propTypes = {
  uuid: PropTypes.string,
  barCode: PropTypes.string,
  linkTicket: PropTypes.string,
  pixQrCodeUrl: PropTypes.string,
  pixCode: PropTypes.string,
};

export default CardBankBillPaymentSuccess;
